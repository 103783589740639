import React from 'react';
import Container from '../components/core/layout/Layout/Container';
import Heading from '../components/core/typography/Heading';
import List from '../components/core/lists/List';
import ListItem from '../components/core/lists/ListItem';
import { graphql, Link } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '../components/core/buttons/Button';
import SEO from '../components/core/meta/SEO';
import PageHeader from '../components/views/PageHeader/PageHeader';

const SUBPAGE_INDEX: { url: string; name: string }[] = [
  {
    name: 'L’assegno di accompagnamento',
    url: '#assegnoaccompagnamento',
  },
  {
    name: 'I requisiti per l’assegno di accompagnamento per anziani',
    url: '#requisiti',
  },
  {
    name: 'Come fare la domanda di accompagnamento per anziani?',
    url: '#domanda',
  },
  {
    name: 'Diritti all’accompagnamento per i ricoverati',
    url: '#diritti',
  },
  {
    name: 'Assegno sociale',
    url: '#assegnosociale',
  },
];

const IndemnityPage2 = () => {
  const indemnityImages = useStaticQuery<any>(graphql`
    query IndemnityImages2 {
      doctor: file(relativePath: { eq: "doctor.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      oldpeople1: file(relativePath: { eq: "indemnity/old-people1.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      oldpeople2: file(relativePath: { eq: "indemnity/old-people2.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <>
      <SEO
        title="L'indennità di accompagnamento per anziani"
        url="/accompagnamento-anziani-indennita"
        description="Tutto quello che devi sapere sull'indennità di accompagnamento per anziani e l'assegno sociale. Scopri tutte le curiosità!"
      />
      <PageHeader
        backgroundImage={indemnityImages.doctor.childImageSharp.gatsbyImageData}
      >
        <Container>
          <Heading
            variant="h1"
            className="text-white mb-0"
            style={{ color: '#ffffff' }}
          >
            {"L'INDENNITA' DI ACCOMPAGNAMENTO PER GLI ANZIANI"}
          </Heading>

          <Heading
            variant="h5"
            className="mb-16 text-primary-500"
            style={{ color: '#f9b233' }}
          >
            Che cos’è l’indennità di accompagnamento
          </Heading>
        </Container>
      </PageHeader>
      <div className="py-16">
        <section>
          <Container>
            <div className="flex flex-wrap -mx-4 items-center">
              <div className="w-full md:w-1/2 px-4">
                <GatsbyImage
                  image={indemnityImages.doctor.childImageSharp.gatsbyImageData}
                  alt="doctore"
                  className="rounded-md overflow-hidden"
                />
              </div>
              <div className="w-full md:w-1/2 px-4">
                <Heading variant="h5">Indice</Heading>
                <List>
                  {SUBPAGE_INDEX.map(p => (
                    <ListItem key={p.url}>
                      <Link className="font-bold text-primary-500" to={p.url}>
                        {p.name}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </div>
            </div>
          </Container>
        </section>
        <section className="py-16">
          <Container>
            <div className="py-8 border-b border-gray-300">
              <Heading id="assegnoaccompagnamento" variant="h4">
                L’assegno di accompagnamento
              </Heading>
              <p className="text-paragraph">
                I soggetti che hanno raggiunto l’età pensionabile e a cui è
                stata riconosciuta una grave invalidità possono fare richiesta
                per ricevere l’<b>assegno di accompagnamento</b>. Quest’ultimo
                consiste in un sostegno economico erogato dall’INPS a favore di
                coloro che necessitano di assistenza nel compimento delle
                attività quotidiane. Per le persone anziane, ossia coloro che
                hanno già raggiunto la soglia dell’età pensionabile, la{' '}
                <b>valutazione dell’invalidità</b> avviene in maniera differente
                rispetto a coloro che ancora lavorano; in entrambi i casi
                precedentemente riportati, è necessario che il singolo soggetto
                sia impossibilitato a camminare o a compiere in autonomia
                attività di vita quotidiana.
              </p>
            </div>
            <div className="py-8 border-b border-gray-300">
              <Heading variant="h4" id="requisiti">
                I requisiti per l’assegno di accompagnamento per anziani
              </Heading>
              <p className="text-paragraph mb-4">
                La legge 104, detta anche Ma l’assegno di accompagnamento per
                anziani spetta a tutte le persone anziane indistintamente? La
                risposta è no. Gli aventi diritto all’
                <b>indennità di accompagnamento</b> per anzianità sono:
              </p>
              <List className="mb-4">
                <ListItem>
                  Soggetti invalidi totalmente e in modo permanente;
                </ListItem>
                <ListItem>
                  {' '}
                  Individui impossibilitati a camminare in maniera autonoma o a
                  svolgere attività quotidiane in autonomia;
                </ListItem>
                <ListItem>
                  Persone aventi la cittadinanza italiana o europea, o di un
                  Paese extraeuropeo se l’interessato ha ottenuto un permesso di
                  soggiorno per soggiornare per il lungo periodo;
                </ListItem>
              </List>
              <p className="text-paragraph">
                Ma è bene precisare cosa si intende per attività quotidiane:
                “Per atti quotidiani della vita sono da intendersi il complesso
                di tali funzioni quotidiane della vita individualizzabili in
                alcuni atti interdipendenti o complementari nel quadro
                esistenziale d’ogni giorno: vestizione, nutrizione, igiene
                personale, espletamento dei bisogni fisiologici, effettuazione
                degli acquisti e compere, preparazione dei cibi, spostamento
                nell’ambiente domestico o per il raggiungimento del luogo di
                lavoro, capacità di accudire alle faccende domestiche,
                conoscenza del valore del denaro, orientamento tempo-spaziale,
                possibilità di attuare condizioni di autosoccorso e di chiedere
                soccorso, lettura, messa in funzione della radio e della
                televisione, guida dell’automobile per necessità quotidiane
                legate a funzioni vitali, ecc”. (Circolare del Ministero del
                Tesoro 14/1992).
              </p>
            </div>
            <div className="py-8 border-b border-gray-300">
              <Heading variant="h4" id="domanda">
                Come fare la domanda di accompagnamento per anziani?
              </Heading>
              <p className="text-paragraph mb-4">
                Per coloro che hanno già raggiunto l’età del pensionamento,
                esiste la possibilità di usufruire di una{' '}
                <b>
                  nuova procedura semplificata della domanda di accompagnamento
                  per anziani
                </b>
                . La novità di tale procedura consiste nel comunicare
                anticipatamente i dati amministrativi per la concessione
                dell’accompagnamento, anticipando in tal modo l’invio all’INPS
                del modello AP70 senza dover attendere l’emissione del verbale.
                Tuttavia, per poter esercitare il diritto all’indennità risulta
                comunque necessario attendere l’esito dei nuovi accertamenti
                sanitari.
              </p>
              <p className="text-paragraph mb-4">
                Il modello AP70 deve essere inviato entro i 30 giorni dal
                ricevimento dello stesso; tuttavia, tale scadenza non è
                tassativa, infatti il modello può essere inviato all’INPS entro
                10 anni a partire dal mese successivo a quello in cui è stata
                inviata la domanda. È bene sottolineare, che i{' '}
                <b>termini di pagamento della prestazione di invalidità</b>{' '}
                decorrono a partire dal mese successivo a quello in cui è stato
                inviata la suddetta domanda.
              </p>
              <p className="text-paragraph mb-4">
                Per l’invio del modulo è possibile anche procedere con la
                compilazione del modello telematico disponibile sul sito dell’
                <a
                  className="font-bold text-primary-500"
                  href="https://www.inps.it/nuovoportaleinps/default.aspx?iiDServizio=2729"
                  target="_blank"
                  rel="noreferrer"
                >
                  INPS
                </a>
                ; per il suo rilascio verranno richiesti dei dati obbligatori
                comprensivi dei dati anagrafici, i recapiti, gli eventuali dati
                del coniuge, del rappresentante legale e la sezione relativa
                all’accertamento richiesto.
              </p>
              <p className="text-paragraph mb-4">
                I dati, una volta inseriti, transiteranno in maniera del tutto
                automatica nella fase relativa alla{' '}
                <b>concessione dell’indennità</b>, in seguito alla definizione
                del verbale sanitario che riconosce il diritto
                all’accompagnamento degli anziani.
              </p>
            </div>
            <div className="py-8 border-b border-gray-300">
              <Heading variant="h4" id="diritti">
                Diritti all’accompagnamento per i ricoverati
              </Heading>
              <p className="text-paragraph mb-4">
                Dal momento che i soggetti ricoverati non hanno diritto a
                percepire l’assegno di accompagnamento, per poter procedere alla
                sua liquidazione deve risultare la dimissione dell’interessato.
                Si ricorda infatti che per ricevere l’a
                <b>ssegno di accompagnamento</b>è necessario non risultare
                ricoverati in una struttura sanitaria con retta a carico dello
                Stato, né in un reparto di riabilitazione o di lungodegenza; in
                tal caso i trattamenti verranno ridotti in proporzione alla
                durata del ricovero.
              </p>
              <p className="text-paragraph">
                Siccome l’INPS deve essere informata di tali ricoveri, essa deve
                percepire un’apposita dichiarazione, chiamata <b>ICRIC</b>,
                entro il 31 di marzo (tale dichiarazione deve essere presentata
                ogni anno). Il day hospital non è considerato un ricovero,
                pertanto esso non incide sulla spettanza o meno dell’indennità
                di accompagnamento stessa.
              </p>
            </div>
            <div className="py-8">
              <Heading variant="h4" id="assegnosociale">
                Assegno sociale
              </Heading>
              <p className="text-paragraph mb-4">
                I destinatari di tale aiuto economico sono le persone anziane
                eventi più di 67 anni di età. Esso viene corrisposto dall’INPS
                su richiesta del cittadino e quindi previa domanda rilasciata
                per via telematica. Questa particolare forma di sostegno per le
                persone anziane viene rilasciata in maniera provvisoria, nel
                senso che l’INPS si occupa annualmente di controllare che i
                cittadini che ricevono l’assegno continuino a possedere i
                requisiti di accesso a tale somma di denaro.
              </p>
              <p className="text-paragraph mb-4">
                È importante sottolineare che l’assegno sociale consiste in una
                “prestazione economica, erogata a domanda, dedicata ai cittadini
                italiani e stranieri in condizioni economiche disagiate e con
                redditi inferiori alle soglie previste annualmente dalla
                legge”(inps.it).
              </p>
              <p className="text-paragraph mb-4">
                Chi sono i destinatari dell’assegno sociale? Esso è destinato
                “ai cittadini italiani, agli stranieri comunitari iscritti
                all’anagrafe del comune di residenza e ai cittadini
                extracomunitari/rifugiati/titolari di protezione sussidiaria con
                permesso di soggiorno CE per soggiornanti di lungo periodo. I
                beneficiari devono percepire un reddito al di sotto delle soglie
                stabilite annualmente dalla legge” (inps.it).
              </p>
              <p className="text-paragraph mb-4">
                L’ammontare di questo assegno è pari a €458,00 per 13 mensilità.
                Per il 2019 il limite di reddito è pari a 5.954,00 euro annui e
                11.908,00 euro se il soggetto è coniugato. Infatti, il fatto che
                il soggetto destinatario dell’assegno sia coniugato o meno
                incide sulla sua ricezione: se il ricevente non possiede coniugi
                allora avrà diritto all’assegno in misura intera, se invece
                risulta essere coniugato ha diritto all’assegno in misura
                ridotta.
              </p>
              <p className="text-paragraph mb-4">
                L’assegno sociale viene sospeso nel caso in cui il titolare
                soggiorni all’estero per più di 30 giorni e, a seguito di un
                anno di sospensione viene automaticamente revocato.
              </p>
              <List>
                <ListItem>
                  i trattamenti di fine rapporto e le anticipazioni sui
                  trattamenti stessi;
                </ListItem>
                <ListItem>il reddito della casa di abitazione;</ListItem>
                <ListItem>
                  le competenze arretrate soggette a tassazione separata;
                </ListItem>
                <ListItem>
                  le indennità di accompagnamento per invalidi (link alla pagina
                  di indennità di accompagnamento generale) civili, ciechi
                  civili e le indennità di comunicazione per i sordi;
                </ListItem>
                <ListItem>
                  l’assegno vitalizio erogato agli ex combattenti della guerra
                  1915-1918;
                </ListItem>
                <ListItem>
                  gli arretrati di lavoro dipendente prestato all’estero.
                </ListItem>
              </List>
            </div>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full md:w-1/2 p-4">
                <GatsbyImage
                  image={
                    indemnityImages.oldpeople1.childImageSharp.gatsbyImageData
                  }
                  className="rounded-md"
                  alt="anziani disabili"
                  style={{ height: '340px' }}
                />
              </div>
              <div className="w-full md:w-1/2 p-4">
                <GatsbyImage
                  image={
                    indemnityImages.oldpeople2.childImageSharp.gatsbyImageData
                  }
                  alt="anziani"
                  className="rounded-md"
                  style={{ height: '340px' }}
                />
              </div>
            </div>
            <div className="flex justify-center pt-10">
              <a href="/servizio-accompagnamento-anziani-e-disabili">
                <Button variant="filled" color="primary">
                  Scopri il nostro servizio di accompagnamento e trasporto per
                  disabili
                </Button>
              </a>
            </div>
          </Container>
        </section>
        <section className="bg-primary-300 py-16">
          <Container>
            <Heading variant="h5">
              Come ottenere l’indennità di accompagnamento – Passaggi da seguire
              per non sbagliare
            </Heading>
            <p className="text-paragraph mb-10">
              L’ente preposto all’erogazione dell’indennità di accompagnamento è
              l’INPS, che ne conferma l’ammissibilità sulla base della
              documentazione ricevuta. Vediamo quindi tutti i passaggi per
              ottenere la documentazione e l’indennità di accompagnamento per
              anziani e disabili.
            </p>
            <div className="mb-10">
              <Heading variant="h5" className="mb-10">
                Richiedere il riconoscimento di invalidità.
              </Heading>
              <div className="rounded-md bg-white p-5 shadow-lg md:p-10">
                <p className="text-paragraph">
                  <b>
                    Prendere appuntamento con il proprio medico di famiglia{' '}
                  </b>
                  Al proprio medico è necessario richiedere che venga rilasciato
                  il <b>Certificato Medico Introduttivo</b>. In questo modo si
                  sta richiedendo che avvenga <b>l’Accertamento Sanitario</b>,
                  necessario per riconoscere l’invalidità del richiedente.
                  All’interno del documento il Medico deve indicare i dati
                  anagrafici della persona, la natura della patologia per cui si
                  richiede il riconoscimento dell’invalidità e la prognosi.
                  Alcune informazioni da inserire nel certificato sono ad
                  esempio:
                </p>
                <List className="mb-5">
                  <ListItem>
                    Impossibilità di deambulare senza l’aiuto di un
                    accompagnatore;
                  </ListItem>
                  <ListItem>
                    Impossibilità di compiere gli atti quotidiani della vita
                    senza assistenza;
                  </ListItem>
                  <ListItem>
                    Soggetto affetto da patologia in situazione di gravità;
                  </ListItem>
                  <ListItem>
                    Soggetto affetto da patologia di competenza Anffas
                    (disabilità intellettiva o relazionale);
                  </ListItem>
                  <ListItem>
                    Soggetto affetto da malattia neoplastica (tumore);{' '}
                  </ListItem>
                </List>
                <p className="underline font-bold">
                  Documenti da avere con sé:
                </p>
                <List>
                  <ListItem>Documento di identità del richiedente</ListItem>
                  <ListItem>Codice fiscale del richiedente</ListItem>
                  <ListItem>Tessera sanitaria del richiedente</ListItem>
                </List>
              </div>
            </div>
            <div className="mb-10">
              <Heading variant="h5" className="mb-10">
                Richiedere il riconoscimento di invalidità.
              </Heading>
              <div className="rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
                <p className="text-paragraph">
                  <b>
                    Inviare il Certificato Medico Introduttivo all’INPS e
                    generazione delcodice identificativo della richiesta
                  </b>
                  . Il certificato viene redatto in formato digitale. É compito
                  del medico di famiglia inoltrarlo telematicamente all’INPS
                  tramite l’apposita funzione che si trova sul sito internet
                  dell’ente. Al momento dell’invio, viene rilasciata una
                  ricevuta con un codice identificativo che certifica
                  l’avviamento della procedura. Questa ricevuta viene consegnata
                  dal medico al paziente che richiede l’indennità di
                  accompagnamento,{' '}
                  <b>insieme al certificato introduttivo originale</b>.
                </p>
              </div>
              <div className="rounded-md bg-white p-5 shadow-lg md:p-10">
                <p className="text-paragraph">
                  <b>
                    Fare la visita medica per il riconoscimento dell’invalidità
                  </b>{' '}
                  Il Certificato Medico Introduttivo ha una validità di 30
                  giorni. Il malato viene contattato dall’INPS per la{' '}
                  <b>visita medica che attesti l’invalidità della persona</b>.
                  La visita può essere effettuata presso l’ASL oppure a
                  domicilio. In questo caso è importante ricordarsi di
                  segnalarlo all’interno del Certificato Medico Introduttivo. Al
                  termine della visita medica, il medico rilascia il{' '}
                  <b>certificato di invalidità</b>.
                </p>
                <p className="underline font-bold">
                  {' '}
                  Documenti da avere con sé:{' '}
                </p>
                <List>
                  <ListItem>
                    Certificato inviato all’INPS firmato dal medico di base
                    abilitato
                  </ListItem>
                  <ListItem>
                    Ricevuta di invio – Contenente il codice identificativo
                    della richiesta
                  </ListItem>
                  <ListItem>Documento di identità del richiedente</ListItem>
                  <ListItem>Codice fiscale del richiedente</ListItem>
                  <ListItem>Tessera sanitaria del richiedente</ListItem>
                  <ListItem>
                    Originale e copia della documentazione sanitaria a supporto
                    delle patologie indicate dal medico di base nel certificato
                    inviato all’INPS
                  </ListItem>
                </List>
              </div>
            </div>
            <div>
              <Heading variant="h5" className="mb-10">
                Richiedere l’erogazione dell’indennità di accompagnamento.
              </Heading>
              <div className="rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
                <p className="text-paragraph mb-4">
                  <b>
                    Presentazione della domanda per ottenimento dell’indennità
                    di accompagnamento
                  </b>{' '}
                  È possibile presentare la domanda – inoltrando il certificato
                  di invalidità – in due modalità.
                </p>
                <p className="text-paragraph">
                  Attraverso il sito web: Per poter utilizzare questo canale è
                  necessario essere in possesso del PIN rilasciato dall’INPS. Se
                  non lo si dispone, si può fare richiesta cliccando{' '}
                  <a
                    href="https://serviziweb2.inps.it/RichiestaPin/jsp/menu.jsp"
                    className="font-bold text-primary-500"
                  >
                    qui
                  </a>{' '}
                  o chiamando il numero 803164. Con il codice PIN a disposizione
                  è sufficiente:
                </p>
                <List>
                  <ListItem>
                    Accedere al sito:{' '}
                    <a
                      href="http://www.inps.it/"
                      className="font-bold text-primary-500"
                      target="_blank"
                      rel="noreferrer"
                    >
                      qui
                    </a>{' '}
                  </ListItem>
                  <ListItem>Cliccare nella sezione “Tutti i servizi”</ListItem>
                  <ListItem>
                    Ricercare la sezione: “Invalidità civile – Invio domanda di
                    riconoscimento dei requisiti sanitari”
                  </ListItem>
                  <ListItem>
                    Compilare la domanda e procedere con l’invio
                  </ListItem>
                </List>
                <p className="text-paragraph mb-4">
                  <b>Attraverso enti di patronato</b> o tramite{' '}
                  <b>associazioni di categoria per disabili</b>, come ANMIC,
                  ENS, UIC, ANFASS: in questo caso è necessario recarsi presso
                  la struttura più vicina, consegnare il certificato e compilare
                  la domanda con l’ausilio del personale preposto.
                </p>
              </div>
              <div className="rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
                <p className="text-paragraph">
                  <b>
                    Ricezione del verbale di invalidità civile da parte
                    dell’INPS
                  </b>{' '}
                  Dopo aver visionato la documentazione, l’INPS invia tramite
                  PEC – se fornita in fase di compilazione della domanda – o
                  tramite raccomandata A/R il verbale di invalidità civile.
                </p>
              </div>
              <div className="rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
                <p className="text-paragraph">
                  <b>Presentazione del modulo AP70</b> Come per la precedente
                  domanda, è possibile accedere alla richiesta via sito web o
                  tramite gli enti di patronato. Il modulo da compilare e
                  inviare è il seguente: “Invalidità civile – Invio dati
                  socio-economici e reddituali per la concessione delle
                  prestazioni economiche”, scaricabile sempre dal sito
                  dell’INPS:{' '}
                  <a
                    href="http://www.inps.it/"
                    className="font-bold text-primary-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.inps.it
                  </a>{' '}
                </p>
              </div>
              <div className="rounded-md bg-white p-5 shadow-lg md:p-10">
                <p className="text-paragraph font-bold">
                  Ricezione dell’indennizzo di accompagnamento
                </p>
              </div>
            </div>
          </Container>
        </section>
        <section className="py-12">
          <Container>
            <Heading variant="h5" className="mb-10">
              INFORMAZIONI AGGIUNTIVE SULL’INDENNITA’ DI ACCOMPAGNAMENTO
            </Heading>
            <div className="shadow-lg rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
              <Heading variant="h6">
                Indennità di accompagnamento e compatibilità con pensione di
                invalidità o con l’attività lavorativa
              </Heading>
              <p className="text-paragraph">
                L’indennità di accompagnamento e la pensione di invalidità sono
                compatibili e cumulabili. Non solo: l’indennità di
                accompagnamento risulta compatibile anche con lo svolgimento di
                attività lavorativa, dipendente o autonoma, e con la titolarità
                di una patente speciale.
              </p>
            </div>
            <div className="shadow-lg rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
              <Heading variant="h6">
                Indennità di accompagnamento: incompatibilità
              </Heading>
              <p className="text-paragraph">
                L’indennità di accompagnamento non spetta a coloro che:
              </p>
              <List>
                <ListItem>
                  sono ricoverati gratuitamente in istituto di cura per un
                  periodo superiore a 30 giorni;
                </ListItem>
                <ListItem>
                  percepiscono un’analoga indennità per invalidità contratta per
                  causa di guerra, di lavoro o di servizio.
                </ListItem>
              </List>
            </div>
            <div className="shadow-lg rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
              <Heading variant="h6">
                Rinnovo annuale dell’indennità di accompagnamento
              </Heading>
              <p className="text-paragraph">
                Una volta ottenuta l’indennità, gli interessati dovranno
                produrre annualmente – entro il 31 marzo di ciascun anno – una
                dichiarazione di responsabilità (ICRIC) attestante la permanenza
                dell’invalidità totale
              </p>
            </div>
            <div className="shadow-lg rounded-md bg-white p-5 shadow-lg md:p-10 mb-10">
              <Heading variant="h6">
                Cosa fare in caso non venga riconosciuta l’indennità di
                accompagnamento
              </Heading>
              <p className="text-paragraph">
                In caso il richiedente riceva il verbale di non riconoscimento
                dell’indennità di accompagnamento o non ne venga riconosciuta
                l’indennità, è possibile presentare ricorso. A tal fine il
                richiedente deve prima sottoporsi a un accertamento tecnico
                preventivo, cioè a una verifica preventiva delle condizioni
                sanitarie a sostegno della propria richiesta. In sua mancanza,
                il ricorso non può proseguire. L’accertamento preventivo è
                effettuato, a spese del richiedente, da un consulente tecnico
                nominato dal giudice, in presenza di un medico legale dell’Inps:
                al termine è poi redatta una relazione tecnica, che è trasmessa
                all’Inps e al richiedente. La relazione può essere contestata
                entro 30 giorni: in mancanza di opposizione, viene omologata dal
                Giudice con decreto inappellabile. Chi contesta la relazione
                deve presentare, entro 30 giorni dall’opposizione, il ricorso
                introduttivo del giudizio, specificandone i motivi: da quel
                momento inizia il giudizio vero e proprio, che si conclude con
                una sentenza inappellabile.
              </p>
            </div>
          </Container>
          <div className="flex justify-center pt-10">
            <Link to="/servizio-accompagnamento-anziani-e-disabili">
              <a href="/servizio-accompagnamento-anziani-e-disabili">
                <Button variant="filled" color="primary">
                  Scopri il nostro servizio di accompagnamento e trasporto per
                  disabili
                </Button>
              </a>
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

export default IndemnityPage2;
